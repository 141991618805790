<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="companyName" placeholder="客户名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="userName" placeholder="用户名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="courseName" placeholder="课程名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :data="table.userList"
          :row-style="{height:30+'px'}"
          :cell-style="{padding:5+'px'}"
          border
          v-loading="loading"
          height="650"
          max-height="650"
          :default-sort="{prop: 'id', order: 'ascending'}"
          style="width: 100%">
        <el-table-column prop="companyName" label="客户名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="userName" label="用户" align="center"></el-table-column>
        <el-table-column prop="courseName" label="课程名" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="付款项目" align="center"></el-table-column>
        <el-table-column prop="paymentName" label="付款方式" align="center"></el-table-column>
        <el-table-column prop="proceedsPrice" label="收款金额" align="center"></el-table-column>
        <el-table-column prop="payee" label="收款人" align="center"></el-table-column>
        <el-table-column prop="createDate" label="收款时间" align="center"></el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="warning" size="mini" @click="openEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteProceeds(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <proceedsEdit v-if="dialog.editProceedsState" :proceeds="dialog.proceeds" :state="dialog.editProceedsState" @close="close"/>
  </div>
</template>

<script>
import proceedsEdit from "@/pages/train/proceeds/proceeds-edit";

export default {
  name: "proceeds-list",
  data() {
    return {
      companyName: "",
      userName: "",
      courseName: "",
      tenantCrop: localStorage.getItem("tenantCrop"),
      table: {
        userList: [],
        page: 1,
        limit: 100,
        total: 0,
      },
      dialog: {
        editProceedsState: false,
        proceeds: {},
      }

    }
  },
  components: {
    proceedsEdit
  },
  created() {
    this.queryProceedsList();
  },
  methods: {
    search(){
      this.table.page=1;
      this.queryProceedsList();
    },
    //查询客户列表
    queryProceedsList: function () {
      this.$axios({
        method: "GET",
        url: "/trainProceeds/queryTrainProceedsList",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          companyName: this.companyName,
          userName: this.userName,
          courseName: this.courseName,
          tenantCrop: this.tenantCrop,
        }
      }).then(response => {
        this.table.total = response.data.data.total;
        this.table.userList = response.data.data.list;
        console.log(this.table.userList)
      })
    },
    //打开编辑界面
    openEdit: function (data) {
      this.dialog.editProceedsState = true
      this.dialog.proceeds = data
    },
    //删除员工
    deleteProceeds(data) {
      this.$confirm("删除收款", '删除收款:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "POST",
          url: "/trainProceeds/deleteTrainProceeds",
          params: {
            id: data.id
          }
        }).then(response => {
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self = this;
            setTimeout(function () {
              self.queryProceedsList()
            }, 1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange: function (val) {
      this.table.limit = val;
      this.queryProceedsList();
    },
    handleCurrentChange(val) {
      this.table.page = val
      this.queryProceedsList()
    },
    close: function (state) {
      this.dialog.editProceedsState = state;
      this.queryProceedsList();
    }
  }
}
</script>

<style scoped>

</style>